<template>
  <div class="pt-12 sm:pt-24 text-white">
    <div class="grid grid-cols-1 gap-6">
      <div class="flex justify-center">
        <img src="../../media/logo/logo-white.svg" alt="" class="h-32 md:h-48 px-4">
      </div>
      <div class="mx-auto text-neutral-300 md:text-xl uppercase tracking-wider">EVENT | STUDIO | SOCIAL </div>
      <div class="mx-auto text-neutral-200 underline decoration-solid"><a href="https://instagram.com/roberto.gardenier">Contact me on <img src="../../media/icons8-instagram.svg" alt="Instagram" class="h-6 inline"></a></div>
      <div class="mx-auto text-neutral-200 text-xs">Speaks: &#x1f1f3;&#x1f1f1; &#127468;&#127463; &#127477;&#127481; (&#127463;&#127479;)</div>
    </div>
  </div>
</template>

<script setup>

</script>